@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.GrowerDocumentsList {
    .SimpleList {
        padding: 0;
    }

    .SimpleList .table thead > tr > th:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .SimpleList .table th, .SimpleList .table td {
        text-align: left;
    }

    .SimpleList .table td {
        border-bottom: 1px solid $grayscale-light2;
    }

    th:last-child {
        max-width: 250px;
    }

    .file-label {
        display: none;
    }
}