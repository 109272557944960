.VariationsForm {
    .simple-table {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        
        th {
            padding: 10px;
            text-align: left;
            background-color: #f5f5f5;
        }
    }

    .variation-values {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
    }

    .variation-name {
        min-width: 200px;
        border: 0;
        padding: 5px;
        text-align: left;
        display: block;
    }

    .variation-value-center {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    tbody tr td:first-child {
        width: 383px;
        border-right: 2px solid #e0e0e0;
    }

    tbody tr td:not(:first-child) {
        padding-left: 10px;
    }

    .attribute-label {
        cursor: pointer;
    }

    .variation-value-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }

    .pl-2 {
        padding-left: 10px;
    }
}