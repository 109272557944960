@import "src/assets/styles/colors";

.UserStatus {
    .button {
        cursor: pointer;
    }

    &__message {
        text-align: center;
        font-size: 18px;
        color: $grayscale-medium2;
    }
}