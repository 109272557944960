@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.TopBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    height: 64px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /*float: right;*/
    /*width: calc(100% - #{$left-menu-width});*/

    &__spaceBetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__flexEnd{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }
}