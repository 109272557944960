@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SectorFields {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    animation-duration: 0.5s;
    animation-name: slidein;

    @keyframes slidein {
        from {
          opacity: 0;
        }
      
        to {
          opacity: 1;
        }
    }

    @include lg {
        padding-left: 70px;
        padding-right: 70px;
    }

    .file-label{
      display: none;
    }
}

