@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.PasswordInput{

  background-color: $grayscale-light;
  border-radius: $border-radius-md;
  padding: 6px 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 50px;
  justify-content: center;
  position: relative;

  .show-btn{
    position: absolute;
    right: 16px;
    background: none;
    border: none;
    padding: 0;
    svg{
      height: 16px;
      width: 16px;
      path{
        stroke: $grayscale-medium2;
      }
    }
  }

  .text-input-label{
    display: block;
    line-height: 1em;
    font-family: Nunito-Bold,sans-serif;
  }
  .input-el{
    display: block;
    width: 100%;
    background: none;
    border: none;
    line-height: 1em;
    font-size: 1rem;
    padding: 0;
    &:focus{
      outline: none;
    }
    &::placeholder{
      color: $grayscale-medium;
    }
  }
}
