@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.Sector {
    box-shadow: $shadow2;
    width: 100%;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__header {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        position: relative;
        margin-right: 14px;
    }

    &__content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__commands {
        position: relative;
        margin-left: 14px;
        width: 50px;
        cursor: pointer;
    }

    .NextStepButtons {
        width: 100%;
    }
}