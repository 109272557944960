@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.GrowersForm{
    width: 100%;
    height: 100%;

    
    .main-content  {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        @include md {
            padding-right: 50px;
            padding-left: 30px;
            padding-top: 0;
            padding-bottom: 30px;
        }
    }

    .margin-top-mobile {
        margin-top: 2*$margin-base;

        @media screen and (min-width: 991px) {
            margin-top: 0;
        }        
    } 

    .MmSelect{
        min-width: 100%;
    }

    .react-date-picker__clear-button{
        display: none;
    }

    .section-margin-top {
        margin-top: 40px;
    }
    .section-margin-bottom {
        margin-bottom: 40px;
    }

    &__title-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .NextStepButtons{
        margin-top: 55px;
        margin-bottom: 35px;
    
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-direction: row;
    
        @include md {
            
        }
    
        .btnNextStep{
            gap: 10px;
    
            & > svg {
                path {
                    fill: #fff;
                }
            }
        }
    
        &__button{
            width: 100%;
            max-width: 270px;
    
            &--2 {
                max-width: 350px;
            }
        }
    }
}