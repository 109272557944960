@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.FieldErrorMessage {
    color: $danger-color;
    font-size: 0.8rem;
    margin-top: 0.5rem;

    & > span {
        margin-left: 10px;
    }
}