@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.AvatarUpload{    
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    &__control {
        width: 200px;
    }

    &--withContainer{
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: #F8F8F8;
        border-radius: 8px;
        border: 1px solid #F8F8F8;
        transition: all 0.3s ease-in-out;
        padding: 0 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 64px;
    }

    &__title{
        display: block;
        line-height: 1em;
        font-family: Nunito-Bold,sans-serif;
    }

    p{
        margin: 0;
        padding: 0;
    }

    .btn-label{
        color: $text-grey-disabled;
        margin-top: 5px;
    }

    &__preview-container {
        float: left;
        width: 300px;
    }

    &__preview{
        transition: all 0.3s ease-in-out;
        padding-top: 20px;

        &-image {
            width: 100px;
            height: auto;
        }
    }

    .file-label {
        color: $text-grey-disabled;
    }

    .preview {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-bottom: 20px;

        &--square {
            width: 120px;
            height: 120px;
            border-radius: 0;
            border: solid 2px $base-green;
            border-radius: 8px;
        }
    }
}