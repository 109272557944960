@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SelectWithLabel {
    background-color: $grayscale-light;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-height: 64px;
    justify-content: center;
    position: relative;
    padding: 8px 16px;
    padding-bottom: 0;

    .select-label{
        display: block;
        font-family: Nunito-Bold,sans-serif;
        color: $grayscale-medium2;
        font-size: 14px;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    .react-select {
        &__control{
            height: 20px !important;
        }
    }
}