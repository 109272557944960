@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SummaryByProductor {

    .label-productor{
        margin-top: 10px;
        margin-bottom: 5px;
    }

    &__subtotal {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__label {
            font-weight: 700;
            font-size: 16px;
            color: $text-grey;
        }

        &__value {
            font-weight: 700;
            font-size: 16px;
            color: $orange;
        }
    }
}