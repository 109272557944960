@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SimpleAcordeon {
    margin-top: 20px;
    
    &__header {
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;
        font-weight: 700;
        font-size: 20px;
        color: $orange;

        & > .MiniTitle {
            color: $orange;
            font-size: 18px;
        }
    }

    .no-link {
        cursor: default;
    }
}