@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.RoleSelection{
  .left-side{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 10px;
  }
  .right-side{
    min-height: 180px;
  }
  .roles-container{
    width: 545px;
    max-width: 90%;

    .title{
      margin-bottom: 70px;
    }

    .role-block{
      box-shadow: $shadow2;
      padding: 24px;
      display: flex;
      color: $text-color;
      min-height: 128px;
      margin-bottom: 12px;
      border-radius: $border-radius-bg;

      .role-name, .role-desc{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
      }
      .role-name{
        padding: 0 26px 0 0;
        font-family: Nunito-Bold, sans-serif;
        font-size: 18px;
        flex: 1;
      }
      .role-desc{
        font-family: Nunito-Regular, sans-serif;
        flex: 2;
      }
    }
  }
}
