.HalfScreenLayout{
  min-height: 100vh;
  .side-container{
    min-height: 100vh;
    background: center;
    background-size: cover;
  }
  @media only screen and (max-width: 768px){
    min-height: initial;
    .side-container {
      min-height: initial;
    }
  }
}
