@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SideMenu{
    width: $left-menu-width-colapsed;
    height: 100%;
    position: fixed;
    background-color: #fff;
    box-shadow: -3px 0 10px 0 #555;
    float: left;
    font-family: Popppins-Regular;

    @include md {
        width: $left-menu-width;
    }

    .menu-button{
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: $light-green;
        z-index: 999;
        color: $base-green;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 30px;
        height: 30px;
    }

    .title{
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        display: none;

        .logo{
            width: 114px;
        }

        @include md {
            display: flex;
        }
    }

    .list-menu-items{
        &--margin-top{
            margin-top: 75px;
        }
    }
    
    & .sidebar{
        position: fixed;
        height: 100%;
        width: $left-menu-width-colapsed;
        
        @include md {
            width: 220px; 
        }

        /*background-color: red;*/
        color: black;

        & ul {
            list-style-type: none;
            position: relative;
            width: $left-menu-width-colapsed;
            padding-left: 0px;
            min-height: 500px;

            @include md {
                width: 194px;
                padding-left: 22px;
            }
        }

        & li {
            position: relative;
            margin-top: 7px;
            margin-bottom: 7px;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;

            &:hover{
                background-color: $light-green;
                color: $base-green;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent;
            }
        }

        & li a {
            text-decoration: none;
            display: block;
            padding: 5px 10px;
            transition: all 0.2s ease-in-out;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 1px;
            color: $text-grey;
            font-family: Popppins-Regular;
            height: 37px;

            /*&:hover{
                background-color: $light-green;
                color: $base-green;
            }*/
        }

        .menu-item-text {
            padding-left: 10px;
            display: none;

            @include md {
                height: 30px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }

        .menu-item{
            text-align: center;            

            @include md {
                text-align: left;
            }

            &--active{
                background-color: $light-green;
                color: $base-green;
    
                & > a {
                    color: $base-green;
                }

                :not(.Indicator):before {
                    width: 4px;
                    height: 30px;
                    background: $base-green;
                    content: '';
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    display: block;
                    position: absolute;
                    top: 5px;
                    left: -22px;
                }
            }
        } 
    }

    .menu-item-row{
        & > span {
            vertical-align: middle;
        }
    }

    .Indicator{
        position: absolute;
        top: 10px;
        right: 0;
    }

    .icon-container{
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        width: 30px;
        height: 30px;

        & > img{
            margin: auto auto;
        }
    }

    .get-help{
        margin-left: 10px;
        
        &--active{
            & > * > .menu-item-text {
                color: $base-green;
            }
        }

        & > a > span{
            font-family: Popppins-Regular;
            margin-left: 10px;
            color: $text-grey;
            font-weight: 400;
            font-size: 12px;
        }

        @include md {
            margin-left: 40px;
        }
    }
}