@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.Card{
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 20px;
    margin-bottom: 20px;
    /*min-height: 300px;*/

    & .bg-dark-green{
        background-color: $dark-green;
    }
}

.bg-dark-green{
    background-color: $dark-green;
}