@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SearchBarDateFilter{
  width: 100%;
  /*max-width: calc(25% - 10px);*/
  margin-top: $margin-base;
  width: 100%;
  display: flex;

  &--withIcon{
    display: flex;
    width: 100%;
    max-width: 100%;

    .react-date-picker {
      width: 100%;
      height: 17px !important;
      border: 0;
    }
  }

  .react-date-picker__wrapper {
    border: 0;
    background-color: $grayscale-light;
    width: 100%;
  }

  .react-date-picker {
    width: 100%;
    /*height: 40px;*/
    height: 28px;
    border: 0;
  }

  &__calendar-icon{
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 64px;
    background-color: $grayscale-light3;
    display: flex;
    justify-content: center;
    align-items: center;

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;    

    &--error {
      border-left: 1px solid red;
      border-top: 1px solid red;
      border-bottom: 1px solid red;
    }
  }

  &__calendar{
    width: calc(100% - 50px);
    height: 64px;
    border: 0;
    background-color: $grayscale-light;
    min-width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8px;
    /*padding-right: 8px;*/

    &--error {
      border-top: 1px solid red;
      border-bottom: 1px solid red;
      border-right: 1px solid red;
    }

    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;  

    .SearchBarDateFilter__label{
      background-color: $grayscale-light;
      /*width: 35px;*/
      height: 16px;

      font-family: Nunito-Bold,sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #7C7B7B;
    }
  }
}