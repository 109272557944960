@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.OrdersForm {
    width: 100%;
    height: 100%;

    .main-content  {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        @include md {
            padding-right: 50px;
            padding-left: 30px;
            padding-top: 0;
            padding-bottom: 30px;
        }
    }

    .Steps {
        margin-top: 30px;
        margin-bottom: 30px;
        padding-top: 0;
        padding-bottom: 0;
        min-height: 45px;
    }

    .MiniTitle {
        margin-top: 15px;
    }

    .margin-top-mobile {
        margin-top: 2*$margin-base;

        @media screen and (min-width: 991px) {
            margin-top: 0;
        }        
    } 

    .MmSelect{
        min-width: 100%;
    }

    .react-date-picker__clear-button{
        display: none;
    }

    .section-margin-top {
        margin-top: 40px;
    }
    .section-margin-bottom {
        margin-bottom: 40px;
    }

    .SectionSubTitle:first-child {
        margin-top: 0;
    }

    .Message {
        width: 100%;
        max-width: 597px;
    }
}