@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.MmSelect{
    /*min-width: 226px;*/

    .react-select {
        &__input::placeholder{
            color: red !important;
        }

        &__control{
            background-color: $grayscale-light;
            border: none;
            height: 64px;
        }

        &__indicator > svg {
            fill: $base-green !important;
        }

        &__indicator-separator {
            display: none;
        }
    }
}