@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.NotificationsListRightBar {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 600px;
    height: 100%;
    background-color: white;
    border-top-left-radius: 12px;
    padding-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 5;

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    &__mark-as-read {
        color: $base-green;
        cursor: pointer;
    }

    &__list {
        background: rgba(146, 201, 154, 0.2);
        border-top: 1px solid $grayscale-light4;

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 8px;
            gap: 24px;
            min-height: 111px;
            border-bottom: 1px solid $grayscale-light4;

            &--readed {
                background-color: white;
            }

            &__content {
                font-size: 14px;

                &__title {
                    color: $grayscale-bold;
                    font-weight: bold;
                }

                &__subtitle {
                    color: #999;
                }
            }
        }
    }

    .fileName {
        color: $base-green;
        font-weight: bold;
    }
}

.fade-in-slide {
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
  }

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}