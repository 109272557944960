@import "src/assets/styles/colors";

.Login{
  .side-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 10px;
  }
  .form-container{
    width: 440px;
    max-width: 90%;
  }
  .title{
    line-height: 32px;
  }
  .subtitle{
    color: $base-green;
    font-family: Nunito-ExtraBold, sans-serif;
    font-size: 20px;
  }
  .forgot-section{
    text-align: right;
    margin: 36px 0 52px 0;
  }
  .submit, .google{
    width: 100%;
    margin-bottom: 30px;
  }
  .plus-icon{
    margin-right: 10px;
  }
  .sign-up-text{
    text-align: center;
    margin-top: 6px;
    font-family: Nunito-SemiBold, sans-serif;
  }
  .sign-up-link{
    font-family: Nunito-Bold, sans-serif;
    text-decoration: underline;
  }

  .right-side{
    align-items: end;
    @media only screen and (max-width: 768px){
      align-items: start;
      padding-top: 20px;
    }
  }

  .text-block{
    color: white;
    font-family: Nunito-Bold, sans-serif;
    font-size: 31px;
    border-radius: 12px;
    background-color: rgba(255,255,255,0.5);
    padding: 40px 35px;
    width: 90%;
    max-width: 505px;
    line-height: 1.03em;
    margin-bottom: 40px;
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(6px);
    @media only screen and (max-width: 768px){
      font-size: 16px;
      margin-bottom: 0;
    }
  }

}
