@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.TransactionCard{
    .Card{
        padding: $margin-base;
        min-height: auto;
    }

    @include xlg {
        .Card{
            /*min-height: 211px;*/
        }
    }

    &__credits{
        font-size: 24px;
        font-weight: bold;
        color: $text-grey;

        @include xlg {
            font-size: 31px;
        }
    }

    &__credits-label{
        font-size: 14px;
        font-weight: 700;
        color: $text-grey;
    }

    &__sub-title-mini{
        font-size: 14px;
        font-weight: bold;
        color: $text-grey;
        display: inline;
    }

    &__main-title{
        font-size: 14px;
        font-weight: bold;
        color: $text-grey-light;
        margin-bottom: 5px;
    }

    &__sub-title{
        font-size: 16px;
        font-weight: bold;
        color: '#000';
    }

    &__container{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        /*flex-direction: column;*/

        &--mt12{
            margin-top: 12px;
            margin-bottom: 2px;
        }

        /*@include xlg {
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
        }*/
    }

    &__left-content{
        width: 90px;
        /*margin-right: 20px;*/
        flex-direction: column;

        &--half{
            width: 50%;
            font-size: 16px;
        }
    }

    &__right-content{
        width: 100%;
        flex: 1;
    }

    & > .Card .bg-dark-green{
        background-color: $dark-green;
    }

    .flex-base-container{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px;
        gap: 12px;
        color: $text-grey-light;
    }
}