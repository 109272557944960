.FoliarApplicationItem {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    position: relative;
    padding-bottom: 20px;

    .trash-button {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}