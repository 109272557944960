@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.FoliarApplicationRow {
    width: 100%;
    max-width: 450px;
    min-height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        padding: 0 10px;
    }

    &__content-two-cols {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    &__row1 {
        font-weight: 700;
        font-size: 16px;
    }

    &__dose {
        margin-left: 12px;
    }

    &__reason {
        font-weight: 700;
        font-size: 16px;
        color: $text-grey-light;
    }

    .space-between {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__app-date-label {
        font-size: 16px;
        color: $text-grey-light;
        margin-right: 12px;
    }

    &__app-date-value {
        font-size: 16px;
        color: $text-grey-light;
    }
}