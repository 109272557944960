
$text-color: #424242;

$base-green: #92C99A;
$light-green: rgba(146, 201, 154, 0.2);
$accent-blue: #2D5BFF;
$dark-green: #E9F4EB;
$green-light: #DEF3E1;

$grayscale-bold: #181818;
$grayscale-medium: #979797;
$grayscale-medium2: #7C7B7B;
$grayscale-medium3: #828282;
$grayscale-light2: #D7D7D7;
$grayscale-light: #F8F8F8;
$grayscale-light3: #EDEDED;
$grayscale-light4: #E0E0E0;
$grayscale-light5: #F2F2F2;
$grayscale-disabled: #CCC;

$shadow2: 0px 0px 20px rgba(132, 132, 132, 0.2);

$text-grey: #5C5C5C;
$text-grey-disabled: #8A8A8A;
$text-grey-light: #DDDDDD;

$app-background: #F7F8FC;

$attention-color: #F6D523;
$attention-background-color: #FFFAE1;
$delivered-color: #3AC922;
$delivered-background-color: #E6FFE2;
$prospect-background-color: rgba(234, 128, 22, 0.2);
$prospect-color: #F2994A;

$orange: #EA8016;

$button-disabled-background: #EFEFEF;


$red-dark: #FF9E9E;
$red-light: #FF5252;

$danger-color: red;