@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.MMPhoneInput{
    background-color: $grayscale-light;
    border-radius: $border-radius-md;
    padding: 6px 16px;
    display: flex;
    flex-direction: column;
    min-height: 64px;
    justify-content: center;
    position: relative;

    input {
        background-color: $grayscale-light;
        border: none;
    }
}