@import "../../../assets/styles/colors";
@import "../../../assets/styles/measures";

.CustomCheckbox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
        
    
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
            position: absolute;
            left: 0;
            opacity: 0.01;
        }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 33px;
        font-size: 17px;
        cursor: pointer;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 27px;
        height: 27px;
        border: 1px solid #aaa;
        background: #FFF;
        border-radius: 4px;
        box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 $base-green;
        -webkit-transition: all .275s;
                transition: all .275s;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
        content: '✕';
        position: absolute;
        top: 14px;
        left: 4px;
        font-size: 24px;
        color: $base-green;
        line-height: 0;
        -webkit-transition: all .2s;
                transition: all .2s;
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0) rotate(45deg);
                transform: scale(0) rotate(45deg);
    }

    [type="checkbox"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1) rotate(0);
                transform: scale(1) rotate(0);
    }

    /* Disabled checkbox */
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #e9e9e9;
    }

    [type="checkbox"]:disabled:checked + label:after {
        color: #777;
    }

    [type="checkbox"]:disabled + label {
        color: #aaa;
    }

    /* Accessibility */
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
        box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px $base-green;
    }


    .temp{
        input[type="checkbox"] {
            -moz-appearance:none;
            -webkit-appearance:none;
            -o-appearance:none;
            outline: none;
            content: none;

            &:before {
                font-family: "FontAwesome";
                content: "\f00c";
                font-size: 15px;
                color: transparent !important;
                background: white;
                display: block;
                width: 25px;
                height: 25px;
                border: 1px solid $grayscale-light2;
                margin-right: 7px;
            }

            &:checked:before {
                content: "\f00d";
                color: black !important;
            }
        }

        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
            position: absolute;
            left: 0;
            opacity: 0.01;
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
            position: relative;
            padding-left: 2.3em;
            font-size: 1.05em;
            line-height: 1.7;
            cursor: pointer;
        }

        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 1.4em;
            height: 1.4em;
            border: 1px solid #aaa;
            background: #FFF;
            border-radius: .2em;
            box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 $base-green;
            -webkit-transition: all .275s;
                    transition: all .275s;
        }

        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
            content: '✕';
            position: absolute;
            top: .525em;
            left: .18em;
            font-size: 1.375em;
            color: $base-green;
            line-height: 0;
            -webkit-transition: all .2s;
                    transition: all .2s;
        }

        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0) rotate(45deg);
                    transform: scale(0) rotate(45deg);
        }

        [type="checkbox"]:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1) rotate(0);
                    transform: scale(1) rotate(0);
        }

        /* Disabled checkbox */
        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
            box-shadow: none;
            border-color: #bbb;
            background-color: #e9e9e9;
        }

        [type="checkbox"]:disabled:checked + label:after {
            color: #777;
        }

        [type="checkbox"]:disabled + label {
            color: #aaa;
        }

        /* Accessibility */
        [type="checkbox"]:checked:focus + label:before,
        [type="checkbox"]:not(:checked):focus + label:before {
            box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px $base-green;
        }
    }


}