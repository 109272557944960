@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.GrowerHorizontalMenu {
    padding-bottom: 2px !important;
    padding-top: 10px;

    .MenuElement {
        display: inline;
        padding-left: 10px;
        padding-right: 10px;        
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: $text-grey;

        &--active {
            border-bottom: 3px solid $base-green;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: $grayscale-bold;
            cursor: inherit;
        }
    }
}