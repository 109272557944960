@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.Marketplace{
  background-color: #E5E5E5;
  width: 100%;
  height: 100%;

  .right-content{
    width: 100%;
    float: right;
    width: calc(100% - #{$left-menu-width-colapsed});
    background-color: $app-background;

    @include md {
      width: calc(100% - #{$left-menu-width});
    }
  }

  .main-content  {
    padding: 20px;
    min-height: 90vh;

    @include md {
      padding-right: 50px;
      padding-left: 30px;
      padding-top: 0;
      padding-bottom: 30px;
    }
  }

  .margin-top-mobile {
    margin-top: 2*$margin-base;

    @media screen and (min-width: 991px) {
      margin-top: 0;
    }
  }

  .SearchTextbox {
    width: 100%;
    max-width: 732px;
    border-radius: 8px;
    height: 36px;

    .input{
      background-color: white;
      height: 36px;
    }
  }

  .FilterButton{
    height: 36px;
  }

  .product-column{
    max-width: 120px;
  }

  .product-filters{
    margin-top: 45px;
  }

  .ImageWithLabel{
    color: $orange;
    font-weight: bold;
  }

  .CardWithImage__subTitle{
    color: $orange;
  }
}