@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.MiniTitle {
    font-weight: 700;
    font-size: 16px;
    color: $text-grey;

    &.small{
        font-size: 14px;
    }

    &.label{
        background-color: $grayscale-light;
        min-height: 54px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px;
        font-weight: normal;
        border-radius: 8px;
    }
}