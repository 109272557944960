@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.NewGrowerFormStep3 {
    .SectionTitle {
        margin-top: 38px;
        margin-bottom: 12px;
    }

    .Marker {
        width: 40px;
        height: 40px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-20px, -20px);
        padding-bottom: 30px;
    }

    .TextInput {
        margin-bottom: 0;
    }

    .Analysis__subtitle {
        font-style: italic;
        font-weight: 800;
        font-size: 16px;
        line-height: 32px;
        margin-left: 10px;        
    }

    .Link-analysis {
        color: $base-green;
        cursor: pointer;
    }

    .row--border-bottom{
        border-bottom: 1px solid $grayscale-medium2;
        padding-bottom: 34px;
        margin: 0 10px;
        margin-bottom: 10px;
    }

    .center-all{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .SearchBarDateFilter {
        margin-top: 0;
    }

    &__add-button {
        margin-top: 0;
        margin-bottom: 0;
        display: inline;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: $base-green;
    }

    .SectionTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 27px;
    }
}

.mt-modal-input {
    margin-top: 30px;
}

.no-margin-top{
    margin-top: 0;
}