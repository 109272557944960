@import "src/assets/styles/colors";

.TideEntitySelect{
  min-width: 150px;

  .DropdownIndicator{
    border: none;
    background: none;
    color: $text-color;
    font-size: 18px;
    padding-right: 15px;
    .caret{
      transition: transform 300ms;
      &.open{
        transform: rotate(180deg);
      }
    }
  }
  .tide-react-select{
    &__control {
      border: none;

      background-color: $grayscale-light;

      &--menu-is-open {
        border-radius: 20px 20px 0 0;
      }
    }
    &__value-container {
      padding: 7px 15px;
    }
    &__indicator-separator {
      display: none;
    }
    &__menu{
      border-radius: 0 0 20px 20px;
      overflow: hidden;
      padding: 0 15px !important;
      margin-top: 0 !important;
      box-shadow: $shadow2;
      top: unset;
    }
    &__menu-list{
      padding-top: 0;
    }
    &__option{
      &:first-of-type {
        border-top: 1px solid $base-green;
      }
      &, &--is-selected, &--is-focused, &--is-disabled{
        background: white !important;
        color: $text-grey;
        padding-left: 0;
        position: relative;
      }
      &--is-focused{
        cursor: pointer;
      }
    }
  }
}
