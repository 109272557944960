

$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-bg: 16px;

$left-menu-width: 220px;
$left-menu-width-colapsed: 50px;

$margin-base: 14px;
$main-space: 2rem;
