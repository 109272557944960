.MultipleFileUpload {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background: #F8F8F8;
    border: 2px dashed #D7D7D7;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
    height: 120px;
    margin-bottom: 20px;

    .drop-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__preview {
        width: 100px;
        height: 100px;

        & > img {
            width: 100%;
            height: auto;
        }
    }

    &__container-preview {
        float: left;
        padding: 5px;
    }

    &__delete {
        padding: 5px;
        color: green;
        background-color: transparent;
        border: 0;
    }

    &__download {
        margin-top: 20px;
        padding: 5px;
        background-color: transparent;
        border: 0;
    }
}