.FoliarApplicationsForm {
    .TextInput {
        margin-bottom: 0;
    }


    .inner-modal {
        width: 100%;
        max-width: 950px;
    }
}