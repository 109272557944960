@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.OrdersFormStep2 {
    width: 100%;
    height: 100%;

    .main-content  {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        @include md {
            padding-right: 50px;
            padding-left: 30px;
            padding-top: 0;
            padding-bottom: 30px;
        }
    }

    .Steps {
        margin-top: 30px;
        margin-bottom: 30px;
        padding-top: 10px;
        padding-bottom: 20px;
        min-height: 45px;

        @include md {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    .SectionTitle {
        margin: 0;
    }

    .MmSelect, .TextInput {
        margin-bottom: 10px !important;
    }

    .SearchBarDateFilter {
        margin-top: 0;
    }

    .footer-button {
        width: 240px;
    }
}