@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SliderWithLabel {
    padding-left: 20px;

    .label{
        margin-left: 20px;
        margin-bottom: 10px;
    }

    .rc-slider {
        width: calc(100% - 100px);
    }

    .rc-slider-mark-text:first-child {
        position: absolute;
        top: -20px;
        left: -15px !important;
        font-size: 16px;
    }
 
    .rc-slider-mark-text:last-child {
        font-size: 16px;
        position: absolute;
        top: -20px;
        right: 0;
        padding-left: 20px;
    }
    
    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        border-color: $base-green;
        box-shadow: 0 0 0 5px $base-green;
    }
    
    .rc-slider-handle, .rc-slider-handle:hover {
        border: solid 2px $base-green;
    }
    
    .rc-slider-track {
        background-color: $base-green;
        height: 8px;
    }
    
    .rc-slider-dot-active {
        border-color: $base-green;
    }

    .rc-slider-handle {
        position: absolute;
        width: 17px;
        height: 17px;
        margin-top: -5px;
    }

    .rc-slider-dot {
        position: absolute;
        bottom: -8px;
        width: 17px;
        height: 17px;
        vertical-align: middle;
        background-color: #fff;
        border: 2px solid #e9e9e9;
        border-radius: 50%;
        cursor: pointer;
    }
}