@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.OrdersMenu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    min-height: 90px;
    padding: 20px;
    margin: 20px 0;
    list-style: none;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    gap: 20px;

    @include md {
        flex-direction: row;
    }

    &__item {
        border-bottom: 3px solid transparent;

        &--active {
            border-bottom: 3px solid $base-green;
        }
    }
}