@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SearchBar{
    width: 100%;
    min-height: 85px;
    background-color: white;
    margin: 0 0 20px 0;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: $margin-base;
    gap: 15px;
    flex-direction: column;

    @include xlg {
        flex-direction: row;
        gap: 38px;
    }

    &__filters{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: $margin-base;
        flex-direction: column;

        @include md {
            flex-direction: row;
        }

        @include xxlg {
            flex-direction: row;
            /*width: calc(100% - 137px);*/
        }
    }

    &__filter-container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: $margin-base;
        flex-direction: column;

        @include md {
            flex-direction: column;
        }

        @include xxlg {
            /*flex-direction: row;*/
            /*width: calc(100% - 137px);*/
        }
    }

    &__filters--mt-mobile{
        /*margin-top: $margin-base;*/

        @include md {
            margin-top: 0;
        }
    }

    &__input{
        flex: 1;
        border: none;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        background-color: transparent;
        padding: 0;
        margin: 0;
        transition: all 0.3s ease;
    }

    .MmSelect {
        width: 100%;
        min-width: auto;

        @include md {
            max-width: calc(25% - 10px);
        }
    }
}