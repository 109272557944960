@import "colors";
@import "breakpoints";
@import "mixins";
@import "measures";

*{
  box-sizing: border-box;
}
html,body{
  font-family: Nunito-Regular, sans-serif;
  font-size: 16px;
  color: $text-color;
}
input{
  color: $text-color;
}

b, strong, h1{
  font-family: Nunito-Bold, sans-serif;
  font-weight: 600;
}

a{
  font-family: Nunito-ExtraBold, sans-serif;
  text-decoration: none;
  color: $base-green;
  &:hover{
    color: $base-green;
    opacity: 0.6;
  }
}

h1{
  font-size: 49px;
}

.App{
  
}

.img-fluid{
  max-width: 100%;
  height: auto;
}

.dasboard-container{
  min-height: 100vh;
}

.main-content{
  height: 100%;
}

.right-content{
  background-color: #F7F8FC;
  min-height: 100vh;
}

.dashboard-container{
  background-color: #F7F8FC;
}

.right-content{
  width: 100%;
  float: right;
  width: calc(100% - #{$left-menu-width-colapsed});
  background-color: $app-background;

  @include md {
      width: calc(100% - #{$left-menu-width});
  }
}

.gap-3 {
  gap: 1rem!important;
}

.w-50 {
  width: 50%;
  float: left;
}

.bold {
  font-weight: 600;
}

.btn-disabled {
  background-color: $button-disabled-background !important;
  color: $text-grey-disabled !important;
  cursor: not-allowed;
}

.link-disabled {
  color: $text-grey-disabled !important;
  cursor: not-allowed;
}

.p-0 {
  padding: 0;  
} 

.m-0 {
  margin: 0;
}

.margin-top-mobile {
  margin-top: 2*$margin-base;

  @media screen and (min-width: 991px) {
      margin-top: 0;
  }        
} 