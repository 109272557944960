@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.Checkbox{
  display: inline-block;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 2px solid $grayscale-light2;
  border-radius: $border-radius-sm;
  vertical-align: middle;
  position: relative;
  transition: background-color 0.2s;
  &.active{
    border-color: $accent-blue;
    background: $accent-blue;
  }
  .check-input{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
  }
  .icon-cont{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    svg {
      width: 65%;
      path {
        stroke: white;
      }
    }
  }
}
