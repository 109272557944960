@import "src/assets/styles/colors";

.TideToaster{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  .message-bar{
    width: 100%;
    text-align: center;
    padding: 10px;
    &.error{
      background: linear-gradient(45deg, #dc2f2f, #76207c);
      color: white;
    }
    &.info{
      background: linear-gradient(45deg, #2f60dc, #2e207c);
    }
    &.success {
      background: linear-gradient(45deg, #3cb305 30%, #016b01);
      color: white;
    }
  }

  &--v2 {
    //top: auto;
    //bottom: 200px;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      cursor: pointer;
    }

    .message-bar{
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      padding: 17px;
      border-radius: 8px;
      position: relative;

      &__text {
        display: flex;

        > svg {
          margin-right: 10px;
        }
      }

      &.success {
        background: $green-light !important;
        color: black;
        text-align: left;
      }
    }
  }
}
