@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.ProductCard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    height: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &--selected{
      /*background-color: $base-green;*/
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    }

    &:hover{
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    }
    &:active{
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
    }
    &:focus{
      outline: none;
    }
    .ProductCard__image{
      width: 100%;
      height: auto;
      border-radius: 100%;
    }

    .ProductCard__title{
      text-align: center;
      margin-bottom: 0.5rem;
      font-weight: 700;
      color: $text-grey;
      line-height: 32px;
    }
    .ProductCard__price{
        text-align: center;
        margin-bottom: 0.5rem;
    }
    .ProductCard__description{
        text-align: center;
        margin-bottom: 0.5rem;
    }
    .ProductCard__button {
      width: 100%;
      height: 2rem;
      border-radius: 0.5rem;
      background-color: #00a8ff;
      color: #fff;
      text-align: center;
      margin-bottom: 0.5rem;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #00a8ff;
      }

      &:active {
        background-color: #00a8ff;
      }

      &:focus {
        outline: none;
      }
    }
}