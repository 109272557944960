@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.ShipmentDetail {
    background-color: $grayscale-light;
    font-weight: 600;
    font-size: 16px;
    color: $text-grey;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
}