@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.ClientDetails {
    width: 100%;

    &__title {
        font-weight: 700;
        font-size: 20px;
        color: $text-grey;
    }

    &__id {
        font-weight: 600;
        font-size: 16px;
        color: $text-grey-light;
    }

    &__label {
        font-weight: 600;
        font-size: 16px;
        color: $text-grey-light;

        &--mt {
            margin-top: 10px;
        }
    }

    &__value {
        font-weight: 600;
        font-size: 16px;
        color: $text-grey;
    }
}