@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.AddNewSectorButton {
    color: $base-green;    
    cursor: pointer;

    .button-left {
        text-align: right;
    }
}