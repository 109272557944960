.Typography{
    & .text-red{
        color: red;
    }

    & .text-green{
        color: #28B411;
    }

    h2 {
        margin: 0;
    }
}
