@import "../../../assets/styles/colors";
@import "../../../assets/styles/measures";

.TideReactTable {
    position: relative;
    background-color: white;


    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        .table {
            width: 100%;            
        }
    }

    .table-container {
        min-height: 120px;
        padding: 0;
        border-radius: 8px;
        background-color: white;

        &-padding {
            padding: $margin-base;
        }
    }

    .table {
        border-collapse: separate;
        border-spacing: 0 0;

        tr {
            border-radius: 8px;
            background-color: white;
        }

        thead>tr {
            border-radius: 8px;
        }

        thead>tr>th:first-child {
            border-left-style: solid;
            border-top-left-radius: 8px;
            /*border-bottom-left-radius: 8px;*/
            background-color: rgba(146, 201, 154, 0.2);
        }

        thead>tr>th:last-child {
            border-left-style: solid;
            border-top-right-radius: 8px;
            /*border-bottom-right-radius: 8px;*/
            background-color: rgba(146, 201, 154, 0.2);
        }

        thead>tr>th {
            background-color: rgba(146, 201, 154, 0.2);
        }


        th, td {
            border-top: none;
            font-size: 16px;
            font-weight: 700;
            padding: $margin-base;
            text-align: left;
            color: $text-grey;

            &:first-child {
                text-align: left;

            }

            &:last-child {
                text-align: right;

            }
        }

        th {
            font-size: 18px;
            font-weight: bold;
        }

        td {
            border-top: none;
        }

        td {
            border-top: 1px solid #D7D7D7;
            border-bottom: 1px solid #D7D7D7;
        }
    }

    .table-footer{
        padding: $margin-base;
    }

    .avatar-container{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .buyer-with-avatar{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .table-header-with-order-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}