.NotificationsBar{
    /*display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    position: relative;
    z-index: 1;
    */
    min-width: 80px;
    padding-left: 20px;
    
    /*.margin-left-20{
        width: 20px;
        height: 20px;
    } */  
}