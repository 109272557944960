@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.ProductRowCard {
    width: 100%;
    min-height: 160px;
    border-radius: 8px;
    border: 1px solid $grayscale-light2;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    box-shadow: $shadow2;
    color: $grayscale-medium2;
    flex-direction: column;
    position: relative;

    @include lg {
        flex-direction: row;
    }

    &__image {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;

        &:before{
            display: block;
            width: 100%;
            padding-top: 70%;
            content: "";
        }

        @include lg {
            width: 140px;
            height: 140px;
        }
    }

    &__content {
        flex: 1;
    }

    &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: $base-green;
    }

    &__content-right {
        min-width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        min-height: 40px;

        @include lg {
            min-width: 200px;
            min-height: 140px;
        }
    }

    .circle-image{
        border-radius: 100%;
        width: 32px;
        height: 32px;
    }

    &__product-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
        flex: 1;
    }

    &__product-origin {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex: 1;
    }

    .product-label {
        font-style: normal;
        font-size: 14px;
    }

    .product-value {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
    }
    
    .product-data {
        margin-top: 0px;
    }

    .delete-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        color: $grayscale-medium2;
        font-size: 24px;
        transition: color 0.2s ease-in-out;
    }

    .modal-body {
        color: $grayscale-light2 !important;
        text-align: center;
    }
}