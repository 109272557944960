@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.VisitDetails {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;

    &__name {
        color: $orange;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        & > svg {
            cursor: pointer;
        }
    }

    &__date {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: $grayscale-medium2;
    }

    &__productor {
        color: $grayscale-medium2;
        margin-top: 10px;
    }
}