@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.ConfirmEmail{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../../../../../../assets/images/backgrounds/confirmEmailBackground.jpg');
    background-size: cover;
    background-position: center;

    .GeneralContainer{
        background-color: rgba(255, 255, 255, 0.92);
        max-width: 908px;
    }

    .Title {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        line-height: 32px;

        @media (min-width: 768px) {
            font-size: 49px;
        }
    }

    .subTitle {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 1rem;
        line-height: 32px;
        color: $base-green;
    }

    .contentText {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 1rem;
        line-height: 32px;
        margin-top: 35px;

        &--confirm {
            margin-top: 90px;
        }
    }

    .GeneralContainer {
        padding-top: $main-space;
        padding-bottom: $main-space;

        @media (min-width: 768px) {
            padding-top: 115px;
            padding-bottom: 105px;
        }
    }
}