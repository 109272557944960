.NotificationCount {
    margin-left: 15px;
    display: inline;
    position: relative;
    cursor: pointer;

    &__total {
        position: absolute;
        top: -8px;
        right: -8px;
        background-color: #f44336;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        padding: 2px 5px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}