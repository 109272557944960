.TideTooltiper {
    position: fixed;
    z-index: 500;
    min-height: 34px;
    display: flex;
    background: black;
    color: white;
    border-radius: 5px;
    padding: 5px 20px 5px 20px;
    align-items: center;
    box-shadow: 10px 10px 10px 5px rgba(255, 255, 255, 0.2);
    opacity: 0;

    .logo {
        position: absolute;
        height: 34px;
        left: 0;
        top: 0;
    }

    .content {
        font-size: 12px;
    }
}