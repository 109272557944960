@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.CardWithImage{
    width: 100%;
    border-radius: 8px;
    min-height: 310px;
    background-color: white;
    margin-top: 20px;
    position: relative;
    box-shadow: $shadow2;

    &not:first-child{
        margin-top: 20px;
    }

    @include lg {
        margin-top: 0;
    }

    &__header-image{
        width: 100%;
        height: auto;
        border-radius: 8px 8px 0 0;
    }

    &__price{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__title{
        height: auto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        padding: 5px 11px;
        margin-top: 10px;
        font-family: "NunitoSans-Light";
    }

    &__subTitle{
        height: auto;
        font-size: 20px;
        font-weight: bold;
        padding: 5px 11px;
    }

    &__productId{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.01em;
        padding: 0 11px;
        color: $text-grey-light;
    }

    &__unit{
        font-weight: 800;
    }

    &__content{
        padding: 0 11px;
        margin-top: 15px;
        min-height: 50px;
        font-size: 14px;
        color: $text-grey-light;
    }

    &__like{
        position: absolute;
        top: 5px;
        right: 5px;
        width: 44px;
        height: 44px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        transition: all 0.5s ease-out 100ms;
        border: 1px solid transparent;
        cursor: pointer;
        background: rgba(124, 123, 123, 0.39);
        backdrop-filter: blur(3px);
        border-radius: 8px;

        /*&:hover{
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 0.5s ease-out 100ms;
            border: 1px solid white;
        }*/

        &--active{
            background-color: rgba(184, 13, 13, 0.5);
            transition: all 0.5s ease-out 100ms;
        }
    }

    &__promotion {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;
        position: absolute;
        width: 92px;
        height: 33px;
        left: 11px;
        top: 5px;
        background: $base-green;
        border: 1px solid #EFEFEF;
        border-radius: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: white;
    }
}