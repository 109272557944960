@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SelectedProductDetail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    & > img {
        width: 86px;
        height: 86px;
        object-fit: cover;
        border-radius: 8px;
    }

    &__product {
        font-weight: 700;
        font-size: 20px;
        font-weight: bold;
        padding: 0;
        margin: 0 !important;
        color: $text-grey;
    }

    &__id {
        font-size: 16px;
        padding: 0;
        margin: 0 !important;
        color: $text-grey-light;
    }
}