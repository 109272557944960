@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.ActionUpload {
    color: $base-green;
    font-family: Nunito-ExtraBold, sans-serif;
    gap: 10px;

    .gap10 {
        gap: 10px;
    }

    .cursor-not-allowed{
        cursor: not-allowed;
        color: $grayscale-light2;
    
        & > svg > {
            path: {
                fill: $grayscale-light2 !important;
            }
        }
    }

    .UploadFile--withContainer {
        background-color: white;
        border: 0;
        min-height: auto;   
        padding: 0;
    }

    .upload-file-label {
        color: $text-grey;
        font-family: Nunito, sans-serif;
    }
}