@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.NewGrowerFormStep2 {
    .btn-no-border{
        border: none;
        gap: 8px;
        font-size: 16px;
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        border-color: $base-green;
        box-shadow: 0 0 0 5px $base-green;
    }
    
    .rc-slider-handle, .rc-slider-handle:hover {
        border: solid 2px $base-green;
    }
    
    .rc-slider-track {
        background-color: $base-green;
        height: 8px;
    }
    
    .rc-slider-dot-active {
        border-color: $base-green;
    }

    .rc-slider-handle {
        position: absolute;
        width: 17px;
        height: 17px;
        margin-top: -5px;
    }

    .rc-slider-dot {
        position: absolute;
        bottom: -8px;
        width: 17px;
        height: 17px;
        vertical-align: middle;
        background-color: #fff;
        border: 2px solid #e9e9e9;
        border-radius: 50%;
        cursor: pointer;
    }

    &__slider {
        padding-left: 30px;
    }

    .btn-delete{
        cursor: pointer;
    }

    .avatar {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-bottom: 20px;

        &--square {
            width: 120px;
            height: 120px;
            border-radius: 0;
            border: solid 2px $base-green;
            border-radius: 8px;
        }
    }

    .files-validations-label {
        color: $text-grey-disabled;
    }
}