@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.Dashboard{
    background-color: #E5E5E5;
    width: 100%;
    height: 100%;

    .right-content{
        width: 100%;
        float: right;
        width: calc(100% - #{$left-menu-width-colapsed});
        background-color: $app-background;

        @include md {
            width: calc(100% - #{$left-menu-width});
        }
    }

    .main-content  {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        @include md {
            padding-right: 50px;
            padding-left: 30px;
            padding-top: 0;
            padding-bottom: 30px;
        }
    }

    .margin-top-mobile {
        margin-top: 2*$margin-base;

        @media screen and (min-width: 991px) {
            margin-top: 0;
        }        
    } 
}