@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.TextInput{

  background-color: $grayscale-light;
  border-radius: $border-radius-md;
  padding: 6px 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 66px;
  justify-content: center;
  position: relative;

  &--disabled {
    border: 0;
    box-shadow: none;
    background-color: $grayscale-disabled;
  }

  .text-input-label{
    display: block;
    line-height: 1em;
    font-family: Nunito-Bold,sans-serif;
    color: $grayscale-medium2;
    font-size: 14px;
  }
  .input-el{
    display: block;
    width: 100%;
    background: none;
    border: none;
    line-height: 1em;
    font-size: 14px;
    padding: 0;
    margin-top: 5px;

    &:focus{
      outline: none;
    }
    &::placeholder{
      color: $grayscale-medium;
    }
  }

  &--small {
    min-height: 50px;

    .input-el{
      margin-top: 0;
      font-size: 1rem;
    }

    .text-input-label{
      color: $text-grey;
      font-size: 16px;
    }
  }  

  .error-message{
    position: absolute;
    top: 55px;
    left: 0;
  }

  .eye-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
  }
}