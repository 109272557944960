@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.AddButton {
    width: 100%;
    min-height: 76px;    
    background-color: $grayscale-light;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D7D7D7' stroke-width='3' stroke-dasharray='20' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
border-radius: 8px;
}