.FilePreview {
    &__file-container {
        width: 150px;
        margin-top: 30px;
        position: relative;
    }

    &__file-icon {
        margin: 0 auto;
        width: 100%;
    }

    &__file-name {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    &__delete-button {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        padding: 0;
    }
}