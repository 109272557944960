@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.Textarea {
    background-color: $grayscale-light;
    border-radius: $border-radius-md;
    padding: 6px 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    min-height: 50px;
    justify-content: center;

    .text-input-label {
        display: block;
        color: $grayscale-medium;
    }

    .input-el {
        display: block;
        width: 100%;
        background: none;
        border: none;
        line-height: 1em;
        font-size: 1rem;
        padding: 0;
        height: 80px;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $grayscale-medium;
        }
    }
}