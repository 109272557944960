@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.ManageUserModal {

    .inner-modal {
        width: 100%;
        max-width: 960px;
    }

    hr {
        padding: 0;
        margin: 0;
    }

    &__permission-name {
        font-weight: 600;
        font-size: 18px;
    }

    &___permission-description {
        font-size: 16px;
        color: $grayscale-medium2;
    }
}