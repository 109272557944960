@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.FirstApplicationMessage {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D7D7D7' stroke-width='3' stroke-dasharray='10' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;

    @include md {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    & > svg {
        width: 43px !important;
        height: 43px !important;
    }

    &__text {
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        width: 100%;
        max-width: 350px;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 30px;
    }
}