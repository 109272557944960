@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.Step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    color: gray;
    transition: all 0.3s ease-in-out;
    font-weight: bold;

    &__title{
        &:before {
            font-size: 21px;
            content:" ";
        }

        &--active {
            color: $base-green;
        }

        &--current {
            &:before {
                font-size: 21px;
                content:"• ";
            }
        }
    }
    
    &:hover {
        
    }

    &__bar {
        background-color: gray;
        width: 100%;
        height: 5px;
        border-radius: 4px;

        &--active {
            background-color: $base-green;
        }
    }
}