@font-face {
  font-family: "Nunito-SemiBold";
  src: url("./Nunito/Nunito-SemiBold.ttf");
}
@font-face {
  font-family: "Nunito-Bold";
  src: url("./Nunito/Nunito-Bold.ttf");
}
@font-face {
  font-family: "Nunito-ExtraBold";
  src: url("./Nunito/Nunito-ExtraBold.ttf");
}
@font-face {
  font-family: "Nunito-Regular";
  src: url("./Nunito/Nunito-Regular.ttf");
}

@font-face {
  font-family: "NunitoSans-Regular";
  src: url("./NunitoSans/NunitoSans-Regular.ttf");
}
@font-face {
  font-family: "NunitoSans-Light";
  src: url("./NunitoSans/NunitoSans-Light.ttf");
}

@font-face {
  font-family: "Popppins-Regular";
  src: url("./Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Mulish-Regular";
  src: url("./Mulish/Mulish-Regular.ttf");
}
