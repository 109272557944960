@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.Breadcrumbs{
    margin-top: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    &__Link {
        display: inline;
    }

    &__separator {
        display: inline;
        margin-left: 10px;
    }

    > span {
        color: $text-grey;
    }

    a {
        color: $text-grey-light;
    }
}