@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.ImageWithLabel{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .image-container{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    a {
        color: $orange;
        text-decoration: underline;
    }
}