.ClientLogo {
    width: 123px;
    height: 123px;
    background-color: black;
    border-radius: 8px;
    margin-right: 15px;
    min-width: 123px;

    &__image {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
}