@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.Message {
    width: 100%;
    height: 100%;
    min-height: 70px;
    padding: 15px;
    margin: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &--success {
        border: 1px solid $base-green;
        background-color: rgba($base-green, 0.2);
    }

    &--error {
        border: none;
        color: red;
        min-height: 30px;

        > .Message__icon {
            width: 10px;
            height: 10px;
        }
    }

    &__icon{
        width: 44px;
        height: 44px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        width: 100%;
    }
}