@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.FilterButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 137px;
    height: 44px;
    border-radius: 4px;
    background-color: rgba(146, 201, 154, 0.2);
    color: $base-green;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    border: none;
    margin: 0 auto;
    margin-top: $margin-base;

    @include lg {
        margin: none;
        margin-top: 0;
    }

    & svg {
        margin-right: 10px;
    }

    &:hover{
        background-color: rgba(58, 201, 34, 0.4);
    }
    &:active{
        background-color: #ebebeb;
    }
    &:focus{
        outline: none;
    }
}