.ProductDetailsForm {

    .Message {
        padding: 7px 5px;
        min-height: 39px;
        height: auto;
    }

    .input-error {
        border: 1px solid #ff0000;
    }
}