.GrowerChangeStatusButtons {
    width: 345px;
    display: flex;
    justify-content: flex-end;
    align-items: center;


    &__button {
        gap: 10px;
        font-weight: 800;
        font-size: 16px;
        width: 168px;
        height: 44px;
    }
}