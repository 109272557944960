.NewGrowerFormStep1{
    .MmSelect .react-select__control {
        height: 64px;
        border-radius: 8px;
    }

    &__buttonsContainer{
        margin-top: 60px;
        margin-bottom: 60px;

        .Button{
            min-width: 240px;
        }
    }

    .TextInput {
        margin-bottom: 0;
    }

    .formField:not(:first-child) {
        margin-top: 20px;
    }
}