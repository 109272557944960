@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.FoliarApplicationsList {
    width: 100%;
    height: 100%;

    .main-content  {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        @include md {
            padding-right: 50px;
            padding-left: 30px;
            padding-top: 0;
            padding-bottom: 30px;
        }
    }

    .margin-top-mobile {
        margin-top: 2*$margin-base;

        @media screen and (min-width: 991px) {
            margin-top: 0;
        }        
    }

    .select-filter {
        width: 100%;

        @include md {
            width: 50%;
        }
    }

    .filter-container {
        background-color: white;
        border-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 20px;
    }
}