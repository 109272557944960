.UploadAvatarButton {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 8px;

    &__text {
        display: block;
        position: absolute;
        top: calc(50% + 20px);
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        width: 100px;
        text-align: center;
    }
}