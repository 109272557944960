
// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra LArge devices
@mixin xlg {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

// Super extra large

@mixin xxlg {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
}

@mixin fillParent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}