.GeneralContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.92);
    border-radius: 12px;
    padding: 2rem;
    width: 100%;
    max-width: 908px;
}