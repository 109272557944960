@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.OrderStatus {
    
    .Card {
        margin-bottom: 0;
    }

    &__info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;

        & > svg {
            width: 30px;
            height: 30px;
        }
    }

    .rc-slider-disabled .rc-slider-track {
        background-color: $base-green;
    }

    .rc-slider-disabled {
        background-color: transparent;
    }
}