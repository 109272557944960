@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.RightFilterBar {
  width: 377px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -400px;
  background-color: #fff;
  box-shadow: -5px 0px 2px rgba(0, 0, 0, 0.1);
  transition: all 1s ease-in-out;
  padding: 0 30px;

  &--open {
    right: 0;
  }

  &__close-container {
    width: 100%;
    height: 54px;
  }

  &__close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  &__titles {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__title {
    font-size: 31px;
    font-weight: 700;
    color: $text-grey;
  }

  &__clear-link {
    color: $base-green;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
  }

  &__filters {
    margin-top: $margin-base;
  }

  &__filter-label {
    margin-top: $margin-base;
  }

  .rc-slider{
    height: 34px;
    width: 95%;
    margin: 0 auto;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: $base-green;
    box-shadow: 0 0 0 5px $base-green;
  }
  .rc-slider-handle, .rc-slider-handle:hover {
    border: solid 2px $base-green;
  }

  .rc-slider-track {
    background-color: $base-green;
  }

  .rc-slider-dot-active {
    border-color: $base-green;
  }

  .ButtonsContainer{
    margin-top: 2*$margin-base;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cancelButton{
    color: $base-green;
    width: 53px;
    height: 24px;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }

  .Button--primary{
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
  }
}